<template>
	<main class="page-container" id="join-intro">
		<section class="mbrp-section">
			<div class="container">
				<header class="mbrp-header">
					<h2 class="title">동행축제 회원 가입하기</h2>
				</header>
				<div class="mbrp-body">
					<!-- steps -->
					<div class="steps-wrapper">
						<ol class="steps">
							<li class="steps-segment is-active">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">1</span>
									</span>
									<div class="steps-content">
										<p class="heading">약관동의</p>
									</div>
								</div>
							</li>
							<li class="steps-segment">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">2</span>
									</span>
									<div class="steps-content">
										<p class="heading">회원정보 입력</p>
									</div>
								</div>
							</li>
							<li class="steps-segment">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">3</span>
									</span>
									<div class="steps-content">
										<p class="heading">회원가입 완료</p>
									</div>
								</div>
							</li>
						</ol>
					</div>
					<!-- //steps -->

					<div class="form-checkbox is-round is-largetext">
						<input type="checkbox" v-model="allAgree" id="check11" @change="allAgreeClick" />
						<label for="check11">모두 동의합니다</label>
					</div>
					<section class="section">
						<div class="section-header">
							<h4 class="title">
								<div class="form-checkbox is-round">
									<input type="checkbox" v-model="termsAgree" id="check12" @change="agreeClick" />
									<label for="check12">
										<span>이용약관에 재동의<em class="color-orange">(필수)</em></span>
									</label>
								</div>
							</h4>
						</div>
						<div class="section-content">
							<!-- 2024-08-21 수정 이용약관 -->
							<terms-of-use />
						</div>
					</section>
					<section class="section">
						<div class="section-header">
							<h4 class="title">
								<div class="form-checkbox is-round">
									<input type="checkbox" v-model="privacyAgree" id="check13" @change="agreeClick" />
									<label for="check13">
										<span>개인정보 수집 및 이용에 재동의<em class="color-orange">(필수)</em></span>
									</label>
								</div>
							</h4>
						</div>
						<div class="section-content">
							<div class="terms-wrap">
								<section class="terms-section">
									<h4 class="terms-section-title">일반회원용</h4>
									<p>본 기관은 가치삽시다 회원가입 신청을 위하여 아래와 같이 개인정보를 수집·이용하고자 합니다. 내용을 자세히 읽으신 후 동의여부를 결정하여 주십시오.</p>
									개인정보 수집·이용 내역<br />
									<div class="table-responsive">
										<table class="table is-bordered">
											<caption>
												수집·이용 항목, 수집·이용 목적, 보유기간 목록
											</caption>
											<colgroup>
												<col style="width: 50%" />
												<col style="width: 30%" />
												<col style="width: 20%" />
											</colgroup>
											<thead>
												<tr>
													<th scope="col">수집·이용 항목</th>
													<th scope="col">수집·이용 목적</th>
													<th scope="col">보유기간 목록</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														[필수] <br />
														아이디, 비밀번호, 성명, 휴대전화번호, 이메일주소
													</td>
													<td>
														1. 회원 관리<br />
														2. 민원사무 처리<br />
														3. 재화 또는 서비스 제공<br />
														4. 마케팅 및 광고 활용<br />
														5. 지원사업 관리
													</td>
													<td>
														<span class="color-orange">회원 탈퇴 시 까지</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p>※ 동의를 거부할 권리 및 거부할 경우의 불이익 : 위 개인정보의 수집·이용에 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 저희가 제공하는 서비스 이용에 제한을 받을 수 있습니다.</p>
								</section>
							</div>
						</div>
					</section>
					<div class="buttons text-center">
						<button type="button" class="button-default is-large is-primary is-rounded" @click="clickNext">다음 단계</button>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';
import TermsOfUse from './TermsOfUse';
import DropDown from '@/components/common/DropDown';
import { MUT_SHOW_ALERT, MUT_CLOSE_RETERMS_MODAL } from '@/store/_mut_consts';

export default {
	name: 'JoinDhFesta',
	components: { TermsOfUse },
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
	},
	watch: {},
	data: () => ({
		isLoading: false,
		allAgree: false,
		termsAgree: false,
		privacyAgree: false,
	}),
	mounted() {},
	methods: {
		allAgreeClick() {
			if (this.allAgree) {
				this.termsAgree = true;
				this.privacyAgree = true;
			} else {
				this.termsAgree = false;
				this.privacyAgree = false;
			}
		},
		agreeClick() {
			if (this.privacyAgree && this.termsAgree) {
				this.allAgree = true;
			} else {
				this.allAgree = false;
			}
		},
		clickNext() {
			if (this.allAgree) {
				this.$router.push({ name: 'JoinDhFestaForm' });
			} else {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: `약관 동의를 체크해주세요.` });
			}
		},
	},
	destroyed() {},
};
</script>
